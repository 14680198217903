import { request } from '../utils/request';

const hubAPIs = {
  getAll: params => request.get('/admin/hubs', { params }),
  getOne: id => request.get(`/admin/hubs/find-one/${id}`),
  saveConfig: data => request.post('/hub/save-config', data),
  getHubConfigs: params => request.get('/hub-config/all', { params }),
  deleteHubConfig: hubConfigId => request.delete(`/hub-config/${hubConfigId}`),
  createHubs: data => request.post('/hub/create', data),
  saveHub: hub => request.put(`/admin/hubs/${hub.id}`, hub),
  filterHubs: body => request.post('/admin/hubs/filter', body),
  fetchLaunchGroupChangeLog: data =>
    request.get(
      `/superadmin/launch_group_change_log/${data.id}?source=${data.source}`
    ),
  getAllHubsOfCluster: id => request.get(`/get-all-hubs/${id}`),
  updateBAInvitedHubs: body => request.post('/update-invited-ba-hubs', body),
  getAllBaInvitedHubs: () => request.get('/get-all-ba-invited-hubs'),
  downloadAllBaInvitedHubsCSV: () =>
    request.get('/download-all-invited-hubs-csv-data'),
  syncHubUsers: hubId => request.post(`/analytics/users/sync/${hubId}`),
  deleteHub: hubId => request.delete(`/admin/hubs/${hubId}`),
  getCustomFieldsByHub: hubId =>
    request.get(`/hub-user-fields-by-hub/${hubId}`),
  getHubDomain: hubId => request.get(`/superadmin/hub/${hubId}/domain`),
  getELOrganizaions: () => request.get(`/superadmin/el/organizations`),
  runSocSync: params => request.get(`/superadmin/soc-sync`, { params}),
  runSocLogSync: params => request.get(`/superadmin/soc-log-sync`, { params}),
};

export default hubAPIs;
