import { SET_SOC_SYNC_DATA } from './actions';

const initialState = {
  allAvailableHubs: [],
  hubsLoading: false,
  runningSyncLoader: false,
  runningFailedSyncLoader: false,
};

const socSyncReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_SOC_SYNC_DATA: 
      return { ...state, ...data }
    default: return state
  }
};

export default socSyncReducer;
